const swiper2 = new Swiper('.technologies__carousel', {
    wrapperClass: 'technologies__ribbon',
    slideClass: 'technologies__card',
    direction: 'horizontal',
    loop: false,
    speed: 1000,
    pagination: {
      el: '.technologies__bullets',
      type: 'bullets',
      bulletElement: 'div',
      bulletClass: 'technologies__bullet',
      bulletActiveClass: 'technologies__bullet--active',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
});